
// 引入自定义函数
import { sleep, startLoading, endLoading } from "@/utils/function";
import DownLoad from "../../../../components/DownLoad.vue";
{/* <script src="http://cdn.highcharts.com.cn/highcharts/highcharts.js"></script> */}
let echarts = require('echarts/lib/echarts');
// 引入柱状图组件
require("echarts/lib/chart/bar");
// // 引入饼图组件
// require('echarts/lib/chart/pie');
import { TitleComponent,TooltipComponent,GridComponent   } from 'echarts/components';
import API from '@/api/teacher/developer/virtualExp';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
  name: "virtualExp",
  components: {
    DownLoad
  },

  data() {
    return {
      virtualDate:[],
      activeName:'first',
      expList:[],
      exp_id:'',
      // 虚拟试验详情
      AddFormData:{
        article_id:'',
        des:'',
        pic:[],
        logo:'',
        parameter:[]
        // id:'',
        // name:'',
        // helpurl:'',
        // lab_id:'',
        // cost:'',
        // outday:0,
        // CID:'',
        // status:'',
        // sign:'',
        // basics_total:'',
        // basics_browse:'',
        // create_uid:'',
        // school_id:''
      },
      parameter:[],
      // 上传图片接口地址
      uploadImagePic: process.env.VUE_APP_DOMAIN_URL + "/backend/public/index.php/adminExp/uploadImagePic",
      // 预览图片url
      dialogImageUrl: '',
      dialogVisible: false,
      fileList:[],
      articleLoading:true,
      articleList:[],
      parameter_table:[],
      schoolList : '',
      personalNumber : '',
      personalTimes : '',
      PieChart:{},
      table:[],
      page:1,
      showCharts:false,
      showListNumber:0,
      staticDialog:false,
      census_browse:0,
      census_total:0

    };
  },
  /**
   * @name: 页面创建成功之后
   * @author: camellia
   * @date: 2021-03-04
   */
  created() {
    echarts.use([TitleComponent]);
    echarts.use([TooltipComponent ]);
    echarts.use([GridComponent ]);
    
    this.exp_id = this.$router.currentRoute.query.exp_id?this.$router.currentRoute.query.exp_id:'';
    this.virtualDate[0] = this.$router.currentRoute.query.start?this.$router.currentRoute.query.start:'';
    this.virtualDate[1] = this.$router.currentRoute.query.end?this.$router.currentRoute.query.end:'';
    this.activeName = this.$router.currentRoute.query.activeName?this.$router.currentRoute.query.activeName:'first'
    this.page = this.$router.currentRoute.query.page?this.$router.currentRoute.query.page:1
    this.getDeveloperExpList();
    
  },
  mounted(){},
  methods: {
    jumpFeedData()
    {
      let exp_id = this.exp_id ? this.exp_id : this.expList[0].id;
      window.open("https://app.mools.net/backend/public/index.php/developer/exportDeveloperStatistics?exp_id="+exp_id+"&start_time="+this.virtualDate[0]+"&end_time="+this.virtualDate[1]);
    },
    /**
     * @name: 获取下一页数据
     * @author: lxy
     * @date: 20210908
     */
    getNextData(e)
    {
      let self = this;
      self.page = e
      // this.getstatisData()
      let data = {
        exp_id:self.exp_id ? self.exp_id : self.expList[0].id,
        start_time:self.virtualDate[0],
        end_time:self.virtualDate[1],
        page:self.page
      }
      nprogressStart();
      self.$Loading.show();
      API.getDeveloperStatistics(data)
        .then((res)=>{
          nprogressClose();
          self.$Loading.hide();
          self.schoolList = res.data.showList;
          while(self.schoolList.length < 10)
          {
            self.schoolList.push({
              id: "",
              name: "",
              num: '',
              student: '',
            })
          }
          self.personalNumber = res.data.personalNumber;
          self.personalTimes = res.data.personalTimes;
          self.PieChart = res.data.PieChart;
          self.inSchoolNumber = res.data.PieChart.inSchoolNumber
          self.outSchoolNumber = res.data.PieChart.outSchoolNumber
          self.census_browse = res.data.census_browse;
          self.census_total = res.data.census_total;
          self.$router.push({
            path: "/teacher/developer/virtualExp",
            query: {
              exp_id: self.exp_id,
              activeName: self.activeName,
              start:self.virtualDate[0],
              end:self.virtualDate[1],
              page:self.page
            },
          }).then(()=>{
            nprogressClose();
            self.updateCssStyle(self.exp_id);
          });
        })
    },
    /**
     * @name: 绘制echarts柱形图
     * @author: camellia
     * @date: 2021-05-25
     */
    drawEchartsBar1() {
      let self = this;
      let arr1 = [];
      let arr2 = [];
      self.table?.forEach((item,index)=>{
        arr1.push("关卡" + (index + 1));
        arr2.push(parseFloat((item.avgTime-0)));
      })
      let maxY = Math.max(...arr2);
      // let arr1=["关卡1","关卡2","关卡3","关卡4","关卡5","关卡6"]
      // let arr2 = [0.2,0.3,0.5,0.1,0.6,1]
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("bar1"));
      let chart = Highcharts.chart('bar1',{
        chart: {
          type: 'column'
        },
        title: {
          text: '通关平均时间'
        },
        xAxis: {
          categories: arr1,
        },
        yAxis: {
          min: 0,
          max:maxY,
          title: {
            text: '平均时间/min'
          }
        },
        colors:['#3E95B5'],
        series: [{
          name: '平均时间',
          data: arr2,
          pointWidth:15
        }]
      });
    },
    /**
     * @name: 饼状图制作
     * @author: lxy
     * @date: 20210908
     */
    createPie()
    {
      let self = this;
      var options = {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 70,
            beta: 0
          }
        },
        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 35,
            // dataLabels: {
            //   enabled: true,
            //   format: '{point.name}'
            // }
          }
        },
        colors:['#3E95B5', '#F5BD5C'],
        series: [{
          type: 'pie',
          name: '人数比',
          data: [
            ['本校',self.inSchoolNumber],
            ['外校',self.outSchoolNumber],
          ]
        }]
    };
    // 图表初始化函数
    var chart = Highcharts.chart('container', options);
    },
    /**
     * @name: 获取统计数据(超过一年)
     * @author: lxy
     * @date: 20210908
     */
    confirmgetstatisData()
    {
      let self = this;
      self.staticDialog = false;
      let data = {
        exp_id:self.exp_id ? self.exp_id : self.expList[0].id,
        start_time:self.virtualDate[0],
        end_time:self.virtualDate[1],
        page:self.page
      }
      nprogressStart();
      self.$Loading.show();
      API.getDeveloperStatistics(data)
        .then((res)=>{
          nprogressClose();
          self.$Loading.hide();
          self.schoolList = res.data.showList;
          while(self.schoolList.length < 10)
          {
            self.schoolList.push({
              id: "",
              name: "",
              num: '',
              student: '',
            })
          }
          self.personalNumber = res.data.personalNumber;
          self.personalTimes = res.data.personalTimes;
          self.census_browse = res.data.census_browse;
          self.census_total = res.data.census_total;
          self.table = res.data.table?res.data.table:[];
          self.PieChart = res.data.PieChart;
          self.inSchoolNumber = res.data.PieChart.inSchoolNumber
          self.outSchoolNumber = res.data.PieChart.outSchoolNumber
          self.showListNumber= res.data.showListNumber
          this.createPie();
          this.drawEchartsBar1();
          self.$router.push({
            path: "/teacher/developer/virtualExp",
            query: {
              exp_id: self.exp_id,
              activeName: self.activeName,
              start:self.virtualDate[0],
              end:self.virtualDate[1],
              page:self.page
            },
          }).then(()=>{
            // self.page = self.$router.currentRoute.query.page
            self.updateCssStyle(self.exp_id);
          });
        })
    },
    /**
     * @name: 获取统计数据
     * @author: lxy
     * @date: 20210908
     */
    getstatisData()
    {
      let self = this;
      let startTime = new Date(this.virtualDate[0]).getTime();
      let endTime = new Date(this.virtualDate[1]).getTime();
      if(!startTime||!endTime)
      {
        return this.$Tips({
          // 消息提示内容
          message:'请选择统计时间',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
      if(endTime - startTime > 31536000000)
      {
        this.staticDialog = true;
        return;
        // this.$Tips({
        //   // 消息提示内容
        //   message:'时间过长',
        //   // 消息提示类型（success-成功,warning-警告/失败）
        //   messageType:'warning',
        //   displayTime:1500
        // })
      }
      let data = {
        exp_id:self.exp_id ? self.exp_id : self.expList[0].id,
        start_time:self.virtualDate[0],
        end_time:self.virtualDate[1],
        page:self.page
      }
      nprogressStart();
      self.$Loading.show();
      API.getDeveloperStatistics(data)
        .then((res)=>{
          nprogressClose();
          self.$Loading.hide();
          self.schoolList = res.data.showList;
          while(self.schoolList.length < 10)
          {
            self.schoolList.push({
              id: "",
              name: "",
              num: '',
              student: '',
            })
          }
          self.personalNumber = res.data.personalNumber;
          self.personalTimes = res.data.personalTimes;
          self.census_browse = res.data.census_browse;
          self.census_total = res.data.census_total;
          self.table = res.data.table?res.data.table:[];
          self.PieChart = res.data.PieChart;
          self.inSchoolNumber = res.data.PieChart.inSchoolNumber
          self.outSchoolNumber = res.data.PieChart.outSchoolNumber
          self.showListNumber= res.data.showListNumber
          this.createPie();
          this.drawEchartsBar1();
          self.$router.push({
            path: "/teacher/developer/virtualExp",
            query: {
              exp_id: self.exp_id,
              activeName: self.activeName,
              start:self.virtualDate[0],
              end:self.virtualDate[1],
              page:self.page
            },
          }).then(()=>{
            // self.page = self.$router.currentRoute.query.page
            self.updateCssStyle(self.exp_id);
          });
        })
      
    },
    /**
     * @name: 获取虚拟实验数据
     * @author: lxy
     * @date: 20210908
     */
    getDeveloperExpData()
    {
      let self = this;
      let data = {
        exp_id : self.exp_id ? self.exp_id:self.expList[0]?.id
      }
      nprogressStart();
      self.$Loading.show();
      self.fileList = [];
      API.getDeveloperExpData(data)
          .then((res)=>{
            nprogressClose();
            self.$Loading.hide();
              if(res.code > 0)
              {
                self.articleList.push({
                  id:res.info.article_id,
                  title:res.info.articleName
                })  
              let pic = ''
              try {
                pic = JSON.parse(res.info.pic);
              } catch (error) {
                console.log(error);
              } 
              if(pic)
              {
                  pic?.forEach((item)=>{
                      self.fileList.push({
                          name:'',
                          url:item
                      })
                      // console.log(item);
                  })
              }
              for (const key in self.AddFormData) {
                self.AddFormData[key] = res.info[key]
              }
              try {
                self.AddFormData.parameter = JSON.parse(res.info.parameter)?JSON.parse(res.info.parameter):[];
                self.parameter = JSON.parse(JSON.stringify(self.AddFormData.parameter));
                let table = JSON.parse(res.info.parameter_table);
                self.parameter_table = JSON.parse(table);
              } catch (error) {
                console.log(error);
              }
              self.parameter?.forEach((item,index)=>{
                for (const key in item) {
                  if(item[key]?.substring(0, 1) == '#')
                  {
                    self.parameter[index][key] = '';
                  }
                }
              
                // item?.forEach((value,key)=>{
                //   if(value?.substring(0, 1) == '#')
                //   self.parameter[index][key] = ''
                // })
              })
              self.$router.push({
                path: "/teacher/developer/virtualExp",
                query: {
                  exp_id: self.exp_id,
                  activeName: self.activeName,
                  start:self.virtualDate[0],
                  end:self.virtualDate[1],
                  page:self.page
                },
              }).then(()=>{
                self.updateCssStyle(data.exp_id)
              });
              }
              else
              {
                  self.$Tips({
                      // 消息提示内容
                      message:res.msg,
                      // 消息提示类型（success-成功,warning-警告/失败）
                      messageType:'warning',
                      displayTime:1500
                  })
              }
          })
    },
    /**
     * @name: 获取虚拟实验列表
     * @author: lxy
     * @date: 20210908
     */
    getDeveloperExpList()
    {
      let self = this;
      self.$Loading.show();
      let data = {}
      API.getDeveloperExpList(data)
        .then((res)=>{
            if(res.code > 0)
            {
              self.expList = res.list;
              if (self.expList.length > 0 && self.exp_id =='') 
              {
                sleep(800).then(() => {
                  self.updateCssStyle(self.expList[0].id);
                });
              }
              else
              {
                sleep(800).then(() => {
                  self.updateCssStyle(self.exp_id);
                });
              }
              if(this.activeName == 'first')
              {
                if(!self.virtualDate[0]||!self.virtualDate[1])
                {
                  self.$Loading.hide();
                  return;
                }
                this.getstatisData()
              }
              else
              {
                this.getDeveloperExpData();
              }
              self.$Loading.hide();
            }
            else
            {
              self.$Loading.hide();
              self.$Tips({
                  // 消息提示内容
                  message:res.msg,
                  // 消息提示类型（success-成功,warning-警告/失败）
                  messageType:'warning',
                  displayTime:1500
              })
            }
        })
          
          
    },
    /**
     * @name: 点击tap页
     * @author: lxy
     * @date: 20210908
     */
    tabHandleClick({name})
    {
      let self = this;
      self.activeName = name;
      if(name == 'first')
      {
        self.$router.push({
          path: "/teacher/developer/virtualExp",
          query: {
            exp_id: self.exp_id,
            activeName: self.activeName,
            start:self.virtualDate[0],
            end:self.virtualDate[1],
            page:self.page
          },
        }).then(()=>{
          let exp_id = self.exp_id?self.exp_id:self.expList[0].id
          self.updateCssStyle(exp_id)
          if(self.virtualDate[0]&&self.virtualDate[1])
          {
            self.getstatisData()
          }
        });
        
      }
      else
      {
        self.$router.push({
          path: "/teacher/developer/virtualExp",
          query: {
            exp_id: self.exp_id,
            activeName: self.activeName,
            start:self.virtualDate[0],
            end:self.virtualDate[1],
            page:self.page
          },
        }).then(()=>{
          self.getDeveloperExpData()
        });
      }      
    },
    /**
     * @name: 外部显示图片上传成功
     * @author: LXY
     * @date: 2021-03-01
     */
    handlelogoSuccess(res)
    {
      const self = this;
      if (res.code > 0) 
      {
        self.AddFormData.logo = res.data;
        // Message.success(res.msg);
        this.$Tips({
            // 消息提示内容
            message:res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType:'success',
            displayTime:1500
        })
      } 
      else 
      {
        // Message.error(res.msg);
        this.$Tips({
            // 消息提示内容
            message:res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType:'warning',
            displayTime:1500
        })
      }
    },
    /**
     * @name: 删除轮播图
     * @author: LXY
     * @date: 2021-03-01
     */
    handleRemove(file, fileList) {
      // console.log(fileList);
      this.fileList = fileList
    },
    /**
     * @name: 预览轮播图
     * @author: LXY
     * @date: 2021-03-01
     * @param:	res	json	上传图片返回信息
     */
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    /**
     * @name: 轮播图上传成功
     * @author: LXY
     * @date: 2021-03-01
     * @param:	res	json	上传图片返回信息
     */
    handleAvatarSuccess(res) 
    {
      const self = this;
      if (res.code > 0) 
      {
        self.fileList.push(
            {
                name:'',
                url:res.data
            }
        );
        // Message.success(res.msg);
        this.$Tips({
            // 消息提示内容
            message:res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType:'success',
            displayTime:1500
        })
      } 
      else 
      {
        // Message.error(res.msg);
        this.$Tips({
            // 消息提示内容
            message:res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType:'warning',
            displayTime:1500
        })
      }
    },
    /**
     * @name: 跳转编辑素材
     * @author: lxy
     * @date: 20210908
     */
    editArticle()
    {
        // window.open(`#/teacher/article/articleUpdate?article_id=${this.articleList[0].id}&name=${this.articleList[0].title}&hideMenu=1&type=0`)
        window.open(`#/teacher/article/articleUpdate?article_id=${this.AddFormData.article_id}&hideMenu=1&type=0`)
    },
    /**
     * @name: 预览编辑素材
     * @author: lxy
     * @date: 20210908
     */
    previewArticle()
    {
        //  window.open(`#/teacher/article/articlePreview?article_id=${this.articleList[0].id}&name=${this.articleList[0].title}&hideMenu=1&type=0`)
        window.open(`#/teacher/article/articlePreview?article_id=${this.AddFormData.article_id}&hideMenu=1&type=0`)
    },
    /**
     * @name: 搜索文章
     * @author: lxy
     * @date: 20210908
     */
    getArticleList(e) {
      try {
          let self = this;
          let data = {
              search: e
          }
          if (e) {
              API.getArticleList(data)
                  .then((result) => {
                      if (result.code > 0) {
                          self.articleList = result.articleList;
                          self.articleLoading = false
                      }
                      else {
                          // Message.error('系统错误')
                          this.$Tips({
                              // 消息提示内容
                              message: result.msg,
                              // 消息提示类型（success-成功,warning-警告/失败）
                              messageType: 'warning',
                              displayTime: 1500
                          })
                      }
                  })
          }
      }
      catch (error) {
          // Message.error('系统错误')
      }
    },
    /**
     * @name: 保存
     * @author: LXY
     * @date: 2021-03-01
     */
    save()
    {
      let self = this;
      self.AddFormData.pic= self.fileList.map((item)=>{
          return item.url
      })??[]
      // self.AddFormData.parameter = JSON.stringify(self.parameter);
      self.AddFormData.parameter = self.parameter;
      // 请求参数
      let data = {
          exp_id : self.exp_id ? self.exp_id : self.expList[0].id,
          ...self.AddFormData
      };
      // console.log(data);
      // 添加/修改虚拟实验
      API.updateDeveloperExpData(data)
          .then((result) => {
              if (result.code > 0) {
                  self.getDeveloperExpData();
                  // self.$router.push('/manager/expDetail?id=' + self.AddFormData.id)
                  self.$Tips({
                      // 消息提示内容
                      message: result.msg,
                      // 消息提示类型（success-成功,warning-警告/失败）
                      messageType: 'success',
                      displayTime: 1500
                  })
              }
              else
              {
                  self.$Tips({
                      // 消息提示内容
                      message: result.msg,
                      // 消息提示类型（success-成功,warning-警告/失败）
                      messageType: 'warning',
                      displayTime: 1500
                  })
              }
          })
    },
    /**
     * @name: 点击虚拟实验获取详情
     * @author: LXY
     * @date: 2021-03-03
     * @param:	id	type	虚拟实验id
     */
    getexpInfo(id)
    {
        this.exp_id = id;
        this.updateCssStyle(id)
        if(this.activeName == 'first')
        {
          if(!this.virtualDate[0]||!this.virtualDate[1])
          {
            this.$router.push({
              path: "/teacher/developer/virtualExp",
              query: {
                exp_id: this.exp_id,
                activeName: this.activeName,
                start:this.virtualDate[0],
                end:this.virtualDate[1],
                page:this.page
              },
            }).then(()=>{
              // this.page = this.$router.currentRoute.query.page
              this.updateCssStyle(this.exp_id);
            });
            return;
          }
          this.page = 1;
          this.getstatisData();
          
        }
        else
        {
          this.getDeveloperExpData();
        }

        
    },
    /**
     * @name: 更改css样式
     * @author: camellia
     * @date: 2021-03-03
     * @param:	id	type	description
     */
    updateCssStyle(id) {
      // css效果
      var d = document.querySelectorAll(".left-module tr");
      for (var p = d.length; p--;) {
        if (d[p].id != id) {
          d[p].style.backgroundColor = "#FFFFFF"; /*其他班级*/
          d[p].classList.remove("selectTr");
        } else {
          d[p].style.backgroundColor = "#E5ECF1"; /*点击的班级*/
          d[p].classList.add("selectTr");
        }
      }
    },

    /**
     * @name: 跳转 意见反馈页
     * @author: camellia
     * @date: 2023-02-06
     */
    jumpFeedBack()
    {
      let url = "#/teacher/developer/feedBack?exp_id="+this.exp_id;
      console.log(url);
      window.open(url);
    }
  },
};