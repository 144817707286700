import { request } from "@/request/http";

export default {
  getDeveloperExpList: (data) => {
    return request("POST", `/index.php/developer/getDeveloperExpList`, data);
  },

  updateDeveloperExpData: (data) => {
    return request("POST", `/index.php/developer/updateDeveloperExpData`, data);
  },

  getDeveloperExpData: (data) => {
    return request("POST", `/index.php/developer/getDeveloperExpData`, data);
  },

  getArticleList: (data) => {
    return request("POST", `/index.php/adminExp/getArticleList`, data);
  },

  getDeveloperStatistics: (data) => {
    return request("POST", `/index.php/developer/getDeveloperStatistics`, data);
  },
};