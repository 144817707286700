<template>
  <div class="virtualExp">
    <div v-if="expList.length > 0">
      <!-- 左侧模块 -->
      <div class="left-module">
        <div class="addnewProject">
          <div>
            <div class="projectListBox">
              <table class="sortableTable">
                <tr :id="item.id" v-for="(item, index) in expList" :key="index">
                  <td class="td-project" @click="getexpInfo(item.id)">
                    <!-- <i class="el-icon-success green" v-if="item.isopen"></i> -->
                    {{ item.name }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧模块 -->
      <div class="div border0">
        <el-tabs v-model="activeName" @tab-click="tabHandleClick">
          <el-tab-pane label="统 计" name="first">
            <div class="virtual">
              <div class="selectVir">
                <el-date-picker v-model="virtualDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                <!-- 不点统计无法导出 -->
                <el-button class="themecancelButton chartsButton" @click="getstatisData">统计</el-button>
                <el-button class="themecancelButton chartsButton" @click="jumpFeedData">导出</el-button>
              </div>
              <div>
                <transition name="fade">
                  <div class="developTable" v-show="schoolList.length > 0">
                    <div class="tableTitle">
                      <div class="column">学校</div>
                      <div>人次</div>
                      <div>人数</div>
                    </div>
                    <div class="tableContent" v-for="(item, index) in schoolList" :key="index">
                      <div class="column">{{ item.name }}</div>
                      <div>{{ item.num }}</div>
                      <div>{{ item.student }}</div>
                    </div>
                    <div class="tableTitle" style="border-bottom:1px #CCC solid ">
                      <div class="column">合计</div>
                      <div>{{ personalTimes }}</div>
                      <div>{{ personalNumber }}</div>
                    </div>
                    <div class="tableTitle">
                      <div class="column">总计(包括未实名人员)</div>
                      <div>{{ census_total }}</div>
                      <div>{{ census_browse }}</div>
                    </div>
                    <div class="pagenation">
                      <el-pagination :current-page="Number(page)" @current-change="getNextData" background
                        layout="prev, pager, next" :total="showListNumber">
                      </el-pagination>
                    </div>
                  </div>
                </transition>
                <div class="charts">
                  <div class="chartstext" v-show="schoolList.length > 0">本校/外校人数比</div>
                  <div id="container"></div>
                  <!-- 一个柱形图 -->
                  <div id="bar1" class="bar"></div>
                </div>
              </div>
            </div>
            <div class="save">
              <el-button class="themeButton" @click="jumpFeedBack">意见反馈</el-button>
            </div>
          </el-tab-pane>
          <!-- 设置 -->
          <el-tab-pane label="设 置" name="second">
            <el-form :model="AddFormData" label-width="150px" label-position="left">
              <el-form-item label="轮播图：">
                <el-upload :file-list="fileList" :action="uploadImagePic" :on-success="handleAvatarSuccess"
                  list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="外部显示图">
                <el-upload :action="uploadImagePic" :show-file-list="false" :on-success="handlelogoSuccess">
                  <img v-if="AddFormData.logo" :src="AddFormData.logo" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="帮助素材：">
                <el-select class="articleHelp" v-model="AddFormData.article_id" filterable remote clearable
                  :loading="articleLoading" placeholder="请搜索选择素材！" :remote-method="getArticleList">
                  <el-option v-for="item in articleList" :key="item.id" :label="item.title" :value="item.id">
                  </el-option>
                </el-select>
                <el-button :disabled="!AddFormData.article_id" class="themecancelButton"
                  @click="previewArticle">预览</el-button>
                <el-button :disabled="!AddFormData.article_id" class="themeButton" @click="editArticle">编辑</el-button>
              </el-form-item>
              <el-form-item label="描述：">
                <el-input type="textarea" v-model="AddFormData.des" :autosize="{ minRows: 3, maxRows: 8 }"></el-input>
              </el-form-item>
              <el-form-item label="参数：">
                <div class="setTable">
                  <div v-show="AddFormData.parameter[0]" class="warning-div">#代表正常输入值，留空代表不可编辑</div>
                  <el-row v-for="(trItem, trIndex) in parameter_table" :key="trIndex">
                    <el-col :style="{ width: (100 / Object.keys(trItem).length) + '%' }"
                      v-for="(tdItem, tdIndex) in trItem" :key="tdIndex">
                      <div v-if="tdItem && tdItem.substring(0, 1) == '#'" class="yellow">
                        <el-input v-model="parameter[trIndex][tdIndex]" placeholder="请输入内容"></el-input>
                      </div>
                      <div v-else>
                        <div v-if="tdItem">
                          <el-input v-model="parameter[trIndex][tdIndex]" disabled="disabled"></el-input>
                        </div>
                        <div v-else>
                          <el-input disabled="disabled"></el-input>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-form-item>
            </el-form>
            <div class="save">
              <el-button class="themeButton" @click="save">保存</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div v-else>
      <el-card>
        <div class="noCourse">
          <div class="text">暂无虚拟实验</div>
        </div>
      </el-card>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog title="提示" :visible.sync="staticDialog" width="30%">
      <span>
        <h3>统计区间过长,需要较长的加载时间,您确认等待吗</h3>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button class="themecancelButton" @click="staticDialog = false">取 消</el-button>
        <el-button class="themeButton" @click="confirmgetstatisData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="scss" >
  .virtualExp .el-dialog {
    border-radius: 0.625vw;
  }
  .virtualExp .el-upload-list--picture-card .el-upload-list__item {
    width: 30%;
    height: 15vw;
    overflow: hidden;
  }
  .virtualExp .el-upload-list--picture-card .el-upload-list__item img {
    width: 100%;
    // height: 100%;
  }

  .el-dialog__body {
    padding: 30px 20px;
    padding-top: 5px !important;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
    padding-bottom: 30px;
    text-align: center;
  }

  .virtualExp .is-active {
    border-left: 0 !important;
  }

  .weight .el-table .el-table__cell {
    padding: 0 !important;
  }

  .virtualExp .el-tabs__item.is-active {
    color: #0D5570 !important;
    border-radius: 0.2083vw;
  }

  .virtualExp .el-textarea {
    width: 94% !important;
  }

  .virtualExp .highcharts-credits {
    display: none !important;
  }

  .virtualExp .el-tabs__content {
    overflow: visible;
    height: 75vh !important;
    overflow: scroll !important;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .virtualExp .el-tabs__content::-webkit-scrollbar {
    display: none;
  }

  .virtualExp .el-tabs__active-bar {
    height: 0.2083vw;
    border-radius: 0.2083vw;
    background-color: #0D5570;
  }

  .virtualExp .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #3E95B5;
    color: #FFF;
  }

  .virtualExp .el-tabs__nav-wrap::after {
    content: "";
    height: 0.2083vw;
    border-radius: 0.2083vw;
    z-index: 0 !important;
  }

  .virtualExp .el-tabs__item {
    padding: 0 1.0417vw;
    // margin-right: 1.25rem;
    height: 2.0833vw;
    // width: 4.375vw;
    text-align: center;
    font: normal normal normal 0.8333vw Source Han Sans CN;
  }

  .virtualExp .el-tabs {
    font: normal normal normal 0.8333vw Source Han Sans CN !important;
  }

  .virtualExp .el-range-editor.el-input__inner {
    width: 21.5vw;
  }

  .virtualExp .el-input.is-disabled .el-input__inner {
    //     background-color: #F5F7FA !important;
    // border-color: #ffffff !important;
    // border-left: 0;
    // border-right: 0;
    // border-radius: 0;
    // border: 0;
    //     color: #C0C4CC;
    //     cursor: not-allowed;
  }
</style>
<style lang="scss" scoped>
  @import "@/assets/css/teacher/developer/virtualExp.scss";
</style>

<script>
  import virtualExp from "@/assets/js/teacher/developer/virtualExp.js";
  export default {
    ...virtualExp
  };
</script>